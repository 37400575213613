import React from 'react';

const TopHeader = () => {
    return(
        <div>
            <div className="preloader"></div>

            <header className="main-header header-style-two">
                <div className="header-top bg-box">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-3 col-md-4 col-sm-12 column">
                                <div className="top-left">
                                    <ul className="social-content">
                                        <li>Follow Us:</li>
                                        <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                                        <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                                        <li><a href="#"><i className="fa fa-linkedin"></i></a></li>
                                        <li><a href="#"><i className="fa fa-pinterest-p"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-9 col-md-8 col-sm-12 column">
                                <div className="top-right">
                                    <ul className="right-content">
                                        <li>
                                            <div className="search-box-area">
                                                <div className="search-toggle"><i className="fa fa-search"></i></div>
                                                <div className="search-box">
                                                    <form method="post" className="index.html">
                                                        <div className="form-group">
                                                            <input type="search" name="search" placeholder="Search Here" required />
                                                            <button type="submit"><i className="fa fa-search"></i></button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </li>
                                        <li><a href="#"><i className="fa fa-phone"></i>Call:  +2348060269189</a></li>
                                        <li><a href="#"><i className="fa fa-envelope"></i>Email:  info@jomann.org.ng</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

        </div>
    )
}

export default TopHeader;