import React from 'react';
import CauseDetailHeader from '../components/causeDetail/CauseDetailHeader';
import CauseDetailSection from '../components/causeDetail/CauseDetailSection';

const CauseDetail = () => {
    return (
        <div>
            <CauseDetailHeader />
            <CauseDetailSection />
        </div>
    )
}

export default CauseDetail;