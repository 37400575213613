import React, {useState, useEffect} from 'react';
import { Link } from "react-router-dom";
import {END_POINT} from '../../confis';
import ProgressBar from "@ramonak/react-progress-bar";

const CausesSection = () => {
    const [causes, setCauses] = useState([]);

    useEffect(() => {
        fetch(END_POINT+'/causes')
        .then(response => response.json())
        .then(data => {
            if(data.success){
                setCauses(data.response)
                console.log(data.response)
            }
        }).catch(err => {
            console.log(err)
        })
    }, [])

    return (
        <section className="cause-section cause-style-two causes-grid overlay-style-one sec-pad-2">
            <div className="container">
                <div className="row">
                {causes.map(cause => (
                    <div key={cause._id} className="col-lg-4 col-md-6 col-sm-12 cause-column">
                        <div className="single-cause-content inner-box wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                            <figure className="image-box">
                                <img src={cause.image} alt="" />
                                <div className="overlay-box">
                                    <div className="overlay-inner">
                                        <div className="content">
                                            <a Link to={`/projects/${cause._id}`} className="link"><i className="icon fa fa-link"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </figure>
                            <div className="lower-content">
                                <h4><Link to={`/projects/${cause._id}`}>{cause.title}</Link></h4>
                                <div className="text"></div>
                                <div className="progress-box">
                                    <div>
                                        <ProgressBar completed={60} />
                                    </div> 
                                </div>
                                <ul className="price clearfix">
                                    {/* <li>Raised: <span>₦6,000</span></li> */}
                                    <li>Goal: <span>₦{cause.goal}</span></li>
                                </ul>
                                <div className="donate-box"><Link to={`/projects/${cause._id}`} className="donate-box-btn theme-btn-two">Donate Now</Link></div>
                            </div>
                        </div>
                    </div>
                    ))}
                </div>
                <ul className="pagination centred clearfix">
                    <li><a href="#"><i className="fa fa-angle-left"></i></a></li>
                    <li><a href="#" className="active">1</a></li>
                    <li><a href="#">2</a></li>
                    <li><a href="#">3</a></li>
                    <li><a href="#"><i className="fa fa-angle-right"></i></a></li>
                </ul>
            </div>
        </section>
    )
}

export default CausesSection;