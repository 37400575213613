import React from 'react';
import CausesHeader from '../components/cause/CausesHeader';
import CausesSection from '../components/cause/CausesSection';

const Causes = () => {
    return (
        <div>
            <CausesHeader />
            <CausesSection />
        </div>
    )
}

export default Causes;