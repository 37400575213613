import React from 'react';
import {Link} from "react-router-dom";

const HomeDonateSection = () => {
    return (
        <section className="cta-section cta-style-two overlay-bg" style={{ backgroundImage: `url(${'./assets/images/background/cta-bg-2.jpg'})`}}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-7 col-md-12 col-sm-12 cta-column">
                        <div className="content-box">
                            <div className="title">Become A Volunteer</div>
                            <div className="text">Join your hand with us for a better life and beautiful future</div>
                            <div className="donate-box"><Link to={`/projects`} className="donate-box-btn theme-btn">Donate Now</Link></div>
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-12 col-sm-12 counter-column">
                        <div className="fact-counter centred">
                            <div className="counter-content clearfix">
                                <div className="single-counter-content">
                                    <article className="column wow fadeIn" data-wow-duration="0ms">
                                        <div className="count-outer"><span className="count-text" data-speed="1500" data-stop="12500">0</span></div>
                                    </article>
                                    <div className="text">Donations</div>
                                </div>
                                <div className="single-counter-content">
                                    <article className="column wow fadeIn" data-wow-duration="0ms">
                                        <div className="count-outer"><span className="count-text" data-speed="1500" data-stop="640">0</span></div>
                                    </article>
                                    <div className="text">Volunteers Reached</div>
                                </div>
                                <div className="single-counter-content">
                                    <article className="column wow fadeIn" data-wow-duration="0ms">
                                        <div className="count-outer"><span className="count-text" data-speed="1500" data-stop="320">0</span></div>
                                    </article>
                                    <div className="text">Success Missions</div>
                                </div>
                                <div className="single-counter-content">
                                    <article className="column wow fadeIn" data-wow-duration="0ms">
                                        <div className="count-outer"><span className="count-text" data-speed="1500" data-stop="970">0</span></div>
                                    </article>
                                    <div className="text">Projects Done</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HomeDonateSection