import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
import Footer from '../components/Footer';
import Header from '../components/Header';
import SteakyHeader from '../components/SteakyHeader';
import TopHeader from '../components/TopHeader';
import About from './About';
import BlogDetail from './BlogDetail';
import Blogs from './Blogs';
import CauseDetail from './CauseDetail';
import Causes from './Causes';
import Contact from './Contact';
import Home from './Home';

const Main = () => {
    return (
        <Router>
            <TopHeader />
            <Header />
            <SteakyHeader />
            <Switch>
                <Route path="/" component={Home} exact />
                <Route path="/about" component={About} exact/>
                <Route path="/projects" component={Causes} exact/>
                <Route path="/projects/:id" component={CauseDetail} exact/>
                <Route path="/blogs" component={Blogs} exact/>
                <Route path="/blogs/:id" component={BlogDetail} exact/>
                <Route path="/contact" component={Contact} exact/>
            </Switch>
            <Footer />
        </Router>
    )
}

export default Main