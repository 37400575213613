import React, {useState, useEffect} from 'react';
import { useParams, Link } from "react-router-dom";
import {
    PaystackConsumer,
  } from "react-paystack";
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';

import { withSwalInstance } from 'sweetalert2-react';
import swal from 'sweetalert2';
import {END_POINT, FLUTTERWAVE_LIVE_KEY, FLUTTERWAVE_TEST_KEY} from '../../confis';
import ProgressBar from "@ramonak/react-progress-bar";

const CauseDetailSection = () => {
    const SweetAlert = withSwalInstance(swal);

    let { id } = useParams();
    const [cause, setCause] = useState({});
    const [raisedPer, setRaisedPer] = useState(0);
    const [raised, setRaised] = useState(0);
    const [transaction, setTransaction] = useState({});
    const [show, setShow] = useState(false);
    const [publicKey] = useState("pk_test_8b5e5ae29c8bd3fe46a2ea2ae31cf38b80e2f861")
    const [user, setUser] = useState({
        name: "",
        email: "",
        phone: "",
        amount: "",
        address: ""
      });
    const [showDonate, setShowDonate] = useState(false);

    useEffect(() => {
        console.log(id);
        fetch(END_POINT+'/causes/'+id)
        .then(response => response.json())
        .then(data => {
            setCause(data.response)
            console.log(data.response)
            sumAmountRaised();
        }).catch(err => {
            console.log(err)
        })
        
    }, [])

    const sumAmountRaised = () => {
        fetch(END_POINT+'/transaction/sum/'+id)
        .then(response => response.json())
        .then(data => {
            //setCause(data.response)
            let total = data.response[0].totalValue
            let perRaided = total/cause.goal * 100
            //console.log(perRaided.toFixed(2))
            setRaisedPer(perRaided.toFixed(2))
            setRaised(total)
            console.log(perRaided)
        }).catch(err => {
            console.log(err)
        })
    }

    const createTransaction = (trans) => {
        let data = { 
            name: user.name,
            email: user.email,
            phone: user.phone,
            cause: cause._id,
            amount: user.amount,
            tran_ref: trans.tx_ref,
            trans: trans.transaction_id,
            status: trans.status

        }
        fetch(END_POINT+'/transaction/store', {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
            })
            .then(response => response.json())
            .then(data => {
                setTransaction(data.transaction)
                //console.log('Success:', data);
                setShow(true)
                sumAmountRaised();
                clearUser()
                setShowDonate(false)
            })
            .catch((error) => {
                console.error('Error:', error);
        });
    };
    
     const clearUser = () => {
        setUser({
            name: "",
            email: "",
            phone: "",
            amount: "",
            address: ""
          });
     }

     const config = {
        public_key: FLUTTERWAVE_LIVE_KEY,
        tx_ref: Date.now(),
        amount: user.amount,
        currency: 'NGN',
        payment_options: 'card,mobilemoney,ussd,banktransfer',
        customer: {
          email: user.email,
          phonenumber: user.phone,
          name: user.name,
        },
        customizations: {
          title: cause.title,
          description: 'Payment for '+cause.title,
          logo: 'https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg',
        },
      };

    const handleFlutterPayment = useFlutterwave(config);
    

    const UnsafeComponent = (html) => {
        return { __html: html };
    }

    const donateForm = () => {
        setShowDonate(!showDonate) ;
    }

    return (
        <section className="cause-details">
            <div className="container">
                <div className="row">
                    <div className="col-lg-9 col-md-12 col-sm-12">
                        <div className="cause-details-content">
                            <div className="content-style-one">
                                <figure className="img-box"><img src={cause.image} alt="" /></figure>
                                <div className="title">{cause.title}</div>
                                <div className="single-cause-content clearfix">
                                    <div className="lower-content">
                                    <div className="progress-box">
                                        <div>
                                            <ProgressBar completed={raisedPer} />
                                        </div> 
                                    </div>
                                    
                                        <ul className="price clearfix">
                                            <li>Raised: <span>₦{raised}</span></li>
                                            <li>Goal: <span>₦{cause.goal}</span></li>
                                        </ul>
                                    </div>
                                    <div className="donate-box">
                                        <button onClick={(e) => {donateForm()}} className="donate-box-btn theme-btn-two" >Donate Now</button>
                                    </div>
                                </div>
                                {showDonate ? (
                                   <div>
                                        <div className="popup-inner">
                                            <div className="container">
                                                <div className="donate-form-area">
                                                    <h2>Donation Information</h2>
                                                    <h4>How much would you like to donate:</h4>
                                                    <form  action="#" className="donate-form default-form">
                                                        <ul className="chicklet-list clearfix">
                                                            <div className="input-container" data-message="Every dollar you donate helps end hunger.">
                                                            ₦<input type="text" id="other-amount" placeholder="Enter Amount"  value={user.amount} onChange={(e) => {
                                                            setUser({...user, amount: e.target.value})
                                                        }}   />
                                                            </div>
                                                        </ul>
                                                        <h3>Donor Information</h3>
                                                        <div className="form-bg">
                                                            <div className="row clearfix">
                                                                <div className="col-lg-6 col-md-6 col-sm-12">           
                                                                    <div className="form-group">
                                                                        <p>Your Name*</p>
                                                                        <input type="text" name="name" placeholder="" value={user.name} onChange={(e) => {
                                                                            setUser({...user, name: e.target.value})
                                                                        }} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                                    <div className="form-group">
                                                                        <p>Email*</p>
                                                                        <input type="email" name="email" placeholder="" value={user.email} onChange={(e) => {
                                                                            setUser({...user, email: e.target.value})
                                                                        }}/>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                                    <div className="form-group">
                                                                        <p>Address*</p>
                                                                        <input type="text" name="address" placeholder="" value={user.address} onChange={(e) => {
                                                                            setUser({...user, address: e.target.value})
                                                                        }} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                                    <div className="form-group">
                                                                        <p>Phone Num*</p>
                                                                        <input type="text" name="phone" placeholder="" value={user.phone} onChange={(e) => {
                                                                            setUser({...user, phone: e.target.value})
                                                                        }} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="center">
                                                            <button className="theme-btn" onClick={(e) => {
                                                            e.preventDefault();
                                                            console.log(user)
                                                            handleFlutterPayment({
                                                                callback: (response) => {
                                                                    if(response.status == "completed"){
                                                                        console.log(response);
                                                                        createTransaction(response)
                                                                    }
                                                                
                                                                    closePaymentModal() // this will close the modal programmatically
                                                                },
                                                                onClose: () => {},
                                                            });
                                                            }}>Donate Now</button>
                                                        </div>          
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (<div></div>)}
                        
                                <div className="top-text">
                                    <div dangerouslySetInnerHTML={UnsafeComponent(cause.description)} />
                                </div>
        
                            </div>
                            
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-12 col-sm-12">
                        <div className="sidebar default-sidebar-content cause-sidebar-content">
                            <div className="sidebar-search sidebar-widget">
                                <form action="#" method="post">
                                    <div className="form-group">
                                        <input type="search" name="search-field" placeholder="Search Here.." required="" />
                                        <button type="submit"><i className="flaticon-magnifying-glass"></i></button>
                                    </div>
                                </form>
                            </div>
                            <div className="sidebar-categories sidebar-widget">
                                <div className="sidebar-title">Categories</div>
                                <ul className="categories-list">
                                    <li><a href="#">Homeless</a></li>
                                    <li><a href="#">Food</a></li>
                                    <li><a href="#">Education</a></li>
                                    <li><a href="#">Childrean</a></li>
                                </ul>
                            </div>
                            <div className="sidebar-post sidebar-widget">
                                <div className="sidebar-title">Recent News</div>
                                <div className="single-post">
                                    <figure className="img-box"><a href="blog-details.html"><img src="images/resource/post-1.jpg" alt="" /></a></figure>
                                    <h4><a href="blog-details.html">No Hunger in South Africa</a></h4>
                                    <div className="link"><a href="blog-details.html">See Details <i className="fa fa-angle-right"></i></a></div>
                                </div>
                                <div className="single-post">
                                    <figure className="img-box"><a href="blog-details.html"><img src="images/resource/post-2.jpg" alt="" /></a></figure>
                                    <h4><a href="blog-details.html">Clean Water For Every Child</a></h4>
                                    <div className="link"><a href="blog-details.html">See Details <i className="fa fa-angle-right"></i></a></div>
                                </div>
                                <div className="single-post">
                                    <figure className="img-box"><a href="blog-details.html"><img src="images/resource/post-3.jpg" alt="" /></a></figure>
                                    <h4><a href="blog-details.html">Collect Winter Cloths For..</a></h4>
                                    <div className="link"><a href="blog-details.html">See Details <i className="fa fa-angle-right"></i></a></div>
                                </div>
                            </div>
                            <div className="sidebar-archive sidebar-widget">
                                <div className="sidebar-title">Keyword</div>
                                <ul className="archive-list clearfix">
                                    <li><a href="#">Food</a></li>
                                    <li><a href="#">Charity</a></li>
                                    <li><a href="#">Child</a></li>
                                    <li><a href="#">Children</a></li>
                                    <li><a href="#">Food</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SweetAlert
                    show={show}
                    title="Transaction was successful"
                    html={`<p><strong>Ref:</strong> ${transaction.tran_ref}</p> <p><strong>Amount:</strong> ${transaction.amount}</p> <p><strong>Cause:</strong> ${cause.title}</p>`}
                    icon= 'success'
                    onConfirm={() => setShow(false)}
                />
        </section>

    )
}

export default CauseDetailSection;