import React, {useEffect, useState} from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Link } from "react-router-dom";
import {END_POINT} from '../../confis';

const HomeSection = ({causes}) => {
    return (
    
        <Carousel autoPlay infiniteLoop interval={3000}>
            {causes.map(item => (
                <div className="slide homepage-bgimage" style={{ backgroundImage: `url(${item.image})`}}>
                    <div className="container">
                        <div className="content">
                            <h1 style={{color:"white", position:"relative", fontSize: "68px", fontWeight:"700"}}>You Can<br /><span style={{color: "#800080"}}>Help</span> The Poor</h1>
                            <div className="text" style={{position:"relative", color:"#ffffff",fontSize:"30px",fontWeight:"400", marginBottom:"30px",lineHeight: "32px",display:"inline-block"}}>{item.title}</div>
                            <div className="donate-box"><Link to={`/projects/${item._id}`} className="donate-box-btn theme-btn">Donate Now</Link></div>
                        </div>
                    </div>
                </div>
                    
            ))}
                 
        </Carousel>
    )
}

export default HomeSection;