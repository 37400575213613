import React, {useState, useEffect} from 'react';
import { Link } from "react-router-dom";
import {END_POINT} from '../../confis';
import moment from 'moment';
const BlogsSection = () => {
    const [news, setNews] = useState([]);

    useEffect(() => {
        fetch(END_POINT+'/news')
        .then(response => response.json())
        .then(data => {
            if(data.success){
                setNews(data.response)
                console.log(data.response)
            }    
        }).catch(err => {
            console.log(err)
        })
    }, [])

    const blogDate = (passedDate) => {
        const d = new Date(passedDate)
        console.log(d)
        const showDate = moment(d).format("Do MMM, YYYY")
       return showDate
    }

    return (
        <section className="news-section blog-grid blog-page overlay-style-one sec-pad-2">
            <div className="container">
                <div className="row">
                {news.map(item => (
                    <div key={item._id} className="col-lg-4 col-md-6 col-sm-12 news-column">
                        <div className="single-news-content inner-box">
                            <figure className="image-box">
                                <img src={item.image} alt="" />
                                <div className="overlay-box">
                                    <div className="overlay-inner">
                                        <div className="content">
                                            <Link to={`/blogs/${item._id}`} className="link"><i className="icon fa fa-link"></i></Link>
                                        </div>
                                    </div>
                                </div>
                            </figure>
                            <div className="lower-content">
                                <h4><Link to={`/blogs/${item._id}`}>{item.title}</Link></h4>
                                <div className="time">{blogDate(item.createdAt)}</div>
                                <div className="text">
                                    {/* <div dangerouslySetInnerHTML={UnsafeComponent(item.description)} />; */}
                                </div>
                                <div className="link"><Link to={`/blogs/${item._id}`} className="theme-btn-two">Read More</Link></div>
                            </div>
                        </div>
                    </div>
                ))}
                    
                
                </div>
                <ul className="pagination centred clearfix">
                    <li><a href="#"><i className="fa fa-angle-left"></i></a></li>
                    <li><a href="#" className="active">1</a></li>
                    <li><a href="#">2</a></li>
                    <li><a href="#">3</a></li>
                    <li><a href="#"><i className="fa fa-angle-right"></i></a></li>
                </ul>
            </div>
        </section>
    )
}

export default BlogsSection;