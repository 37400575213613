import React from 'react';
import AboutBody from '../components/about/AboutBody';
import AboutSection from '../components/about/AboutSection';

const About = () => {
    return (
        <div>
            <AboutSection />
            <AboutBody />
        </div>
    )
}

export default About;