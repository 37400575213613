import React from 'react';
import { Link } from "react-router-dom";

const ContactMap = () => {
    return (
        <section className="google-map-section">
        <div className="google-map-area">
            <div 
                className="google-map" 
                id="contact-google-map" 
                data-map-lat="40.652493" 
                data-map-lng="-73.791421" 
                data-icon-path="images/resource/map-marker.png"  
                data-map-title="Brooklyn, New York, United Kingdom" 
                data-map-zoom="12" 
                data-markers='{
                    "marker-1": [40.652493, -73.791421, "<h4>Branch Office</h4><p>77/99 London UK</p>","images/resource/map-marker.png"]
                }'>
                    

            </div>
        </div>
    </section>
    )
}

export default ContactMap;