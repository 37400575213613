import React from 'react';
import { Link } from "react-router-dom";

const CausesHeader = () => {
    return (
        <section className="page-title centred" style={{ backgroundImage: `url(${'./assets/images/help.jpeg'})`}}>
            <div className="container">
                <div className="content-box">
                    <div className="title">Projects</div>
                    <ul className="bread-crumb">
                        <li><Link to="/">Home</Link></li>
                        <li>Projects</li>
                    </ul>
                </div>
            </div>
        </section>
    )
}

export default CausesHeader;