import React, {useState, useEffect} from 'react'
import Header from '../components/Header'
import HomeAbout from '../components/home/HomeAbout';
import HomeCause from '../components/home/HomeCause';
import HomeDonateSection from '../components/home/HomeDonateSection';
import HomeFeatureSection from '../components/home/HomeFeatureSection';
import HomeNews from '../components/home/HomeNews';
import HomeNewsLetter from '../components/home/HomeNewsLetter';
import HomeSection from '../components/home/HomeSection';
import SteakyHeader from '../components/SteakyHeader';
import TopHeader from '../components/TopHeader';
import {END_POINT} from '../confis';

const Home = () => {
    const [causes, setCauses] = useState([]);
    const [causesShow, setCausesShow] = useState([]);
    const [news, setNews] = useState([]);
    const [newsShow, setNewsShow] = useState([]);
    const slideNum = 5
    const showSize = 3
    const showNews = 2
    const sideNews = 3
    useEffect(() => {
        fetch(END_POINT+'/causes')
        .then(response => response.json())
        .then(data => {
            if(data.success){
                setCauses(data.response.slice(0, slideNum))
                setCausesShow(data.response.slice(0, showSize))
                console.log(data.response)
            }
        }).catch(err => {
            console.log(err)
        })
        fetch(END_POINT+'/news')
        .then(response => response.json())
        .then(data => {
            if(data.success){
                setNews(data.response.slice(0, sideNews))
                setNewsShow(data.response.slice(0, showNews))
                console.log(data.response)
            }
        }).catch(err => {
            console.log(err)
        })
    }, [])
    return (
        <div>
            <HomeSection causes={causes} />
            <HomeFeatureSection />
            <HomeAbout />
            <HomeCause causes={causesShow} />
            <HomeDonateSection />
            <HomeNews news={news} showNews={newsShow} />
            <HomeNewsLetter />
        </div>
    )
}

export default Home;