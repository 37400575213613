import React from 'react';
import {Link} from "react-router-dom";
const HomeNews = ({news, showNews}) => {
    return (
        <section className="news-section overlay-style-one sec-pad">
            <div className="container">
                <div className="sec-title centred">Latest Articles</div>
                <div className="title-text centred">Lorem ipsum dolor sit amet consectetur adipisicing elit sed do eiusmo</div>
                <div className="row">
                    {showNews.map(item => (
                        <div className="col-lg-4 col-md-6 col-sm-12 news-column">
                        <div className="single-news-content inner-box">
                            <figure className="image-box">
                                <img src={item.image} alt="" />
                                <div className="overlay-box">
                                    <div className="overlay-inner">
                                        <div className="content">
                                            <Link to={`/blogs/${item._id}`} className="link"><i className="icon fa fa-link"></i></Link>
                                        </div>
                                    </div>
                                </div>
                            </figure>
                            <div className="lower-content">
                                <h4><Link to={`/blogs/${item._id}`}>{item.title}</Link></h4>
                                <div className="time">25 Feb, 2018</div>
                                <div className="text">
                                    <p>Excepteur sint occaecat cupidatat proid ent sunt culpa qui officia derunt mollit anmlab rum sed perspiciatis unde omnis is natus error sit voluptatem.</p>
                                </div>
                                <div className="link"><Link to={`/blogs/${item._id}`} className="theme-btn-two">Read More</Link></div>
                            </div>
                        </div>
                    </div>
                    ))}
                    
                    <div className="col-lg-4 col-md-6 col-sm-12 post-column">
                        <div className="news-post">
                            {news.map(show => (
                                <div className="single-post">
                                <div className="text"><Link to={`/blogs/${show._id}`}>{show.title}</Link></div>
                                <div className="time">20 Mar, 2018</div>
                            </div>
                            ))}
                            <div className="link"><Link to={`/blogs`} className="theme-btn">Read More</Link></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HomeNews