import React from 'react';
import AboutImg from "../../images/about.png"
import { Link } from "react-router-dom";

const HomeAbout = () => {
    return (
        <section className="about-style-two sec-pad">
            <div className="container">
                <div className="sec-title centred">ABOUT <span>JOMANN</span></div>
                <div className="row">
                    <div className="col-lg-6 col-md-12 col-sm-12 about-column">
                        <div className="about-content">
                            <div className="top-content">
                                <div className="title">What we do here</div>
                                <div className="text"><strong>JOMANN</strong> works to improve the quality of life of vulnerable poplutations especially young girls, women and youth. The Founder and CEO, Mrs. Mary Jatau Luka and the Patron Rear Admiral Jatau Kagoma Luka, her husband are both motivated and have privately dedicated over 25 years to giving back to their communities through scholarships, empowerment packages, and social support. Hundreds of lives have been touched in communities in Lagoos where the Foundation's patron served for many years, while Kaduna and Plateau State are not left out of their benevolence.</div>
                                <div className="text">The foundation now creates a working structure to ensure that a greater number of these vulnerable groups received the care, support and resources they need to live and improved quality life.</div>

                                <div className="link"><Link to="/" className="theme-btn">Get Started</Link></div>
                            </div>
                            <div className="lower-content">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-12 column">
                                        <div className="single-item">
                                            <div className="number">01</div>
                                            <h2>Our Vision</h2>
                                            <h4 className="mt-2 mb-3">The World we desire</h4>
                                            <div className="text">To support vulnerable groups, including girls, women and youth, in our communities to achieve sustainable, all round life advancements</div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 column">
                                        <div className="single-item">
                                            <div className="number">02</div>
                                            <h2>Our Mission</h2>
                                            <h4 className="mt-2 mb-3">How we are going to change the World</h4>
                                            <div className="text"><strong>JOMANN</strong> Global Care Fundation provides an enabling environment for the girl child, vulnerable women and youth in rural and underserved communities to survive and strive through structured empowerment program such as:</div>
                                            <div>
                                                <ul className="list">
                                                    <li>Educational support, Skill acqusition through training and capacity building programs.</li>
                                                    <li>Faciliating a roboust mentoring and leadership that will empower benefiaciaries to live thier full potentials</li>
                                                    <li>Creating networks where beneficiaries can be cooperate and be productive</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 img-column">
                        <figure className="img-box"><img height="650px" src={AboutImg} alt="" /></figure>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default HomeAbout