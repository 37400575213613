import React from 'react';
import BlogHeader from '../components/blog/BlogHeader';
import BlogsSection from '../components/blog/BlogsSection';

const Blogs = () => {
    return (
        <div>
            <BlogHeader />
            <BlogsSection />
        </div>
    )
}

export default Blogs;