import React from 'react';

const HomeFeatureSection = () => {
    return (
        <div className="feature-style-two">
            <div className="container-fluid">
                <div className="feature-content">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-12 feature-column">
                            <div className="feature-content-two" style={{ backgroundImage: `url(${'./assets/images/resource/feature-4.jpg'})`}}>
                                <div className="icon-box wow zoomIn" data-wow-delay="300ms" data-wow-duration="1500ms"><i className="flaticon-money-bag"></i></div>
                                <div className="title"><a href="#">Give Donation</a></div>
                                <div className="text"><strong>JOMANN</strong></div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 feature-column">
                            <div className="feature-content-two" style={{ backgroundImage: `url(${'./assets/images/resource/feature-5.jpg'})`}}>
                                <div className="icon-box wow zoomIn" data-wow-delay="600ms" data-wow-duration="1500ms"><i className="flaticon-group"></i></div>
                                <div className="title"><a href="#">Our Volunteer</a></div>
                                <div className="text"><strong>JOMANN</strong></div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 feature-column">
                            <div className="feature-content-two" style={{ backgroundImage: `url(${'./assets/images/resource/feature-6.jpg'})`}}>
                                <div className="icon-box wow zoomIn" data-wow-delay="900ms" data-wow-duration="1500ms"><i className="flaticon-heart"></i></div>
                                <div className="title"><a href="#">Quick Fundraise</a></div>
                                <div className="text"><strong>JOMANN</strong></div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 feature-column">
                            <div className="feature-content-two" style={{ backgroundImage: `url(${'./assets/images/resource/feature-7.jpg'})`}}>
                                <div className="icon-box wow zoomIn" data-wow-delay="1200ms" data-wow-duration="1500ms"><i className="flaticon-money-bag"></i></div>
                                <div className="title"><a href="#">Give Donation</a></div>
                                <div className="text"><strong>JOMANN</strong></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeFeatureSection;