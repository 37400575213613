import React from 'react';
import {Link} from "react-router-dom";
import ProgressBar from "@ramonak/react-progress-bar";

const HomeCause = ({causes}) => {
    return (
        <section className="cause-section cause-style-two overlay-style-one">
            <div className="container">
                <div className="sec-title centred">Featured Projects</div>
                <div className="title-text centred"></div>
                <div className="row">
                    {causes.map(item => (
                        <div className="col-lg-4 col-md-6 col-sm-12 cause-column">
                        <div className="single-cause-content inner-box">
                            <figure className="image-box">
                                <img src={item.image} alt="" />
                                <div className="overlay-box">
                                    <div className="overlay-inner">
                                        <div className="content">
                                            <Link to={`/projects/${item._id}`} className="link"><i className="icon fa fa-link"></i></Link>
                                        </div>
                                    </div>
                                </div>
                            </figure>
                            <div className="lower-content">
                                <h4><Link to={`/projects/${item._id}`}>{item.title}</Link></h4>
                                <div className="text"></div>
                                <div className="progress-box">
                                    <div>
                                        <ProgressBar completed={60} />
                                    </div> 
                                </div>
                                <ul className="price clearfix">
                                    {/* <li>Raised: <span>$6,000</span></li> */}
                                    <li>Goal: <span>₦{item.goal}</span></li>
                                </ul>
                                <div className="donate-box"><Link to={`/projects/${item._id}`} className="donate-box-btn theme-btn-two">Donate Now</Link></div>
                            </div>
                        </div>
                    </div>
                    ))}
                    
                </div>
            </div>
        </section>
    )
}

export default HomeCause