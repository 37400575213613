import React, {useState, useEffect} from 'react';
import {END_POINT} from '../confis';
import { Link } from "react-router-dom";

const Footer = () => {
    const [causes, setCauses] = useState([]);
    const slideNum = 5
    
    useEffect(() => {
        fetch(END_POINT+'/causes')
        .then(response => response.json())
        .then(data => {
            if(data.success){
                setCauses(data.response.slice(0, slideNum))
            }
        }).catch(err => {
            console.log(err)
        })
    }, [])
    return (
        <div>
            <footer className="main-footer">
                <div className="container">
                    <div className="footer-content">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-12 footer-column">
                                <div className="logo-widget footer-widget">
                                    <figure className="logo-box"><a href="index.heml"><img src="./assets/images/logo.jpeg" alt="" height="100px" /></a></figure>
                                    <div className="text">
                                        <p>To support vulnerable groups, including girls, women and youth, in our communities to achieve sustainable, all round life advancements</p>
                                    </div>
                                    <ul className="footer-social">
                                        <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                                        <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                                        <li><a href="#"><i className="fa fa-vimeo"></i></a></li>
                                        <li><a href="#"><i className="fa fa-google-plus"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 offset-lg-2 footer-column">
                                <div className="service-widget footer-widget">
                                    <div className="footer-title">Project</div>
                                    <ul className="list">
                                        {causes?.map((item, index) => (
                                            <li key={index}><Link to={`/projects/${item._id}`}>{item.title}</Link></li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 footer-widget">
                                <div className="contact-widget footer-widget">
                                    <div className="footer-title">Contacts</div>
                                    <div className="text">
                                        <p>Prince and Princess Estate, 1st Gate, Drive 3, House 23B, Abuja</p>
                                        <p>+2348060269189</p>
                                        <p>info@jomann.org.ng</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>


            <div className="footer-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12 column">
                            <div className="copyright"><a href="#">Jomann</a> &copy; 2021 All Right Reserved</div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 column">
                            <ul className="footer-nav">
                                <li><a href="#">Terms of Service</a></li>
                                <li><a href="#">Privacy Policy</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer