import React, {useState, useEffect} from 'react';
import { useParams, Link } from "react-router-dom";
import parse from 'html-react-parser';
import moment from 'moment';
import {END_POINT} from '../../confis';

const BlogDetailSection = () => {

    let { id } = useParams();
    const [blog, setBlog] = useState({});

    useEffect(() => {
        console.log(id);
        fetch(END_POINT+"/news/"+id)
        .then(response => response.json())
        .then(data => {
            if(data.success){
                setBlog(data.response)
                console.log(data.response)
            }
        }).catch(err => {
            console.log(err)
        })
        
    }, [])

    const UnsafeComponent = (html) => {
        return { __html: html };
    }

    const blogDate = (passedDate) => {
        const d = new Date(passedDate)
        console.log(d)
        const showDate = moment(d).format("Do MMM, YYYY")
       return showDate
    }
    return (
        <section className="news-section blog-details blog-page sec-pad-2">
            <div className="container">
                <div className="row">
                    <div className="col-lg-9 col-md-12 col-sm-12 content-side">
                        <div className="blog-details-content">
                            <div className="content-style-one">
                                <figure className="img-box"><img src={blog?.image} alt="" /></figure>
                                <div className="sec-title">{blog?.title}</div>
                                <div className="date">{blogDate(blog?.createdAt)}</div>
                                <div className="text">
                                    <div dangerouslySetInnerHTML={UnsafeComponent(blog.description)} />;
                                </div>
                            </div>
                        
                            <div className="post-share-option clearfix">
                                <ul className="list">
                                {blog?.tags?.map((item, key) => (
                                    <li key={key}><a href="#">{item}</a></li>
                                ))}
                                </ul>
                                <div className="share">
                                    <a href="#"><i className="fa fa-share"></i>Share This</a>
                                    <div className="social-links">
                                        <a href="#"><i className="fa fa-facebook"></i></a>
                                        <a href="#"><i className="fa fa-twitter"></i></a>
                                        <a href="#"><i className="fa fa-google-plus"></i></a>
                                        <a href="#"><i className="fa fa-linkedin"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-12 col-sm-12">
                        <div className="sidebar default-sidebar-content blog-sidebar-content">
                            <div className="sidebar-search sidebar-widget">
                                <form action="#" className="post">
                                    <div className="form-group">
                                        <input type="search" name="search-field" placeholder="Search Here.." required="" />
                                        <button type="submit"><i className="flaticon-magnifying-glass"></i></button>
                                    </div>
                                </form>
                            </div>
                            <div className="sidebar-categories sidebar-widget">
                                <div className="sidebar-title">Categories</div>
                                <ul className="categories-list">
                                    <li><a href="#">Homeless</a></li>
                                    <li><a href="#">Food</a></li>
                                    <li><a href="#">Education</a></li>
                                    <li><a href="#">Childrean</a></li>
                                </ul>
                            </div>
                            <div className="sidebar-post sidebar-widget">
                                <div className="sidebar-title">Recent News</div>
                                <div className="single-post">
                                    <figure className="img-box"><a href="blog-details.html"><img src="images/resource/post-1.jpg" alt="" /></a></figure>
                                    <h4><a href="blog-details.html">No Hunger in South Africa</a></h4>
                                    <div className="link"><a href="blog-details.html">See Details <i className="fa fa-angle-right"></i></a></div>
                                </div>
                                <div className="single-post">
                                    <figure className="img-box"><a href="blog-details.html"><img src="images/resource/post-2.jpg" alt="" /></a></figure>
                                    <h4><a href="blog-details.html">Clean Water For Every Child</a></h4>
                                    <div className="link"><a href="blog-details.html">See Details <i className="fa fa-angle-right"></i></a></div>
                                </div>
                                <div className="single-post">
                                    <figure className="img-box"><a href="blog-details.html"><img src="images/resource/post-3.jpg" alt="" /></a></figure>
                                    <h4><a href="blog-details.html">Collect Winter Cloths For..</a></h4>
                                    <div className="link"><a href="blog-details.html">See Details <i className="fa fa-angle-right"></i></a></div>
                                </div>
                            </div>
                            <div className="sidebar-archive sidebar-widget">
                                <div className="sidebar-title">Keyword</div>
                                <ul className="archive-list clearfix">
                                    <li><a href="#">Food</a></li>
                                    <li><a href="#">Charity</a></li>
                                    <li><a href="#">Child</a></li>
                                    <li><a href="#">Children</a></li>
                                    <li><a href="#">Food</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default BlogDetailSection;