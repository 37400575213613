import React from 'react';
import ContactHeader from '../components/contact/ContactHeader';
import ContactMap from '../components/contact/ContactMap';
import ContactSection from '../components/contact/ContactSection';

const Contact = () => {
    return (
        <div>
            <ContactHeader />
            <ContactSection />
            <ContactMap />
        </div>
    )
}

export default Contact;