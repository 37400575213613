import React from 'react';

const HomeNewsLetter = () => {
    return (
        <section className="subscribe-section">
            <div className="container">
                <div className="subscribe-content">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12 column">
                            <div className="subscribe-title">Subscribe To Our Newsletter</div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 column">
                            <div className="subscribe-form">
                                <form action="#" method="post">
                                    <div className="form-group">
                                        <input type="email" name="email" placeholder="Your Email" required="" />
                                        <button type="submit" className="theme-btn">Subscribe</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HomeNewsLetter