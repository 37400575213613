import React from 'react';
import { Link } from "react-router-dom";

const Header = () => {
    return(
        <div className="header-bottom">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-3 col-md-12 col-sm-12 column">
                        <figure className="logo-box"><Link to="/"><img src="/assets/images/logo.jpeg" alt="" height="100rem" /></Link></figure>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 menu-column">
                        <div className="menu-area">
                            <nav className="main-menu navbar-expand-lg">
                                <div className="navbar-header">
                                    <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                    </button>
                                </div>
                                <div className="navbar-collapse collapse clearfix">
                                    <ul className="navigation clearfix">
                                        <li className="current"><Link to="/">Home</Link></li>
                                        <li><Link to="/about">About</Link></li>
                                        <li><Link to="/projects">Projects</Link></li>
                                        {/* <li><a href="#">Pages</a>
                                            <ul>
                                                <li><a href="event-list.html">Event List</a></li>
                                                <li><a href="event-grid.html">Event Grid</a></li>
                                                <li><a href="event-details.html">Event Details</a></li>
                                                <li><a href="team.html">Our Volunteer</a></li>
                                                <li><a href="portfolio.html">Our Portfolio</a></li>
                                                <li><a href="shop.html">Shop Page</a></li>
                                                <li><a href="shop-details.html">Product Details</a></li>
                                                <li><a href="cart.html">Cart Page</a></li>
                                                <li><a href="checkout.html">Checkout</a></li>
                                                <li><a href="donate-page.html">Donation Page</a></li>
                                                <li><a href="error.html">404 Page</a></li>
                                            </ul>
                                        </li>   */}
                                        <li><Link to="/blogs">Blog</Link></li>                              
                                        <li><Link to="/contact">Contact</Link></li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                    <div className="content-box mt-4">
                        <div className="donate-box">
                            
                            <Link to="/projects" className="donate-box-btn theme-btn">Donate Now</Link></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header;