import React, { useState } from 'react';
import { Link } from "react-router-dom";
import {END_POINT} from '../../confis';

const ContactSection = () => {
    const [loading, setLoading] = useState(false)
    const [show, setShow] = useState('')
    const [message, setMessage] = useState({
        full_name: "",
        email: "",
        phone: "",
        subject: "",
        body: ""
    })
    const createMessage = () => {
        setLoading(true)
        setShow('');
    
        fetch(END_POINT+'/notifications/store', {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(message),
            })
            .then(response => response.json())
            .then(data => {
                if(data.success == true){
                    setLoading(false)
                    setShow(data.message);
                    clear()
                }else{
                    setLoading(false)
                    setShow(data.message);
                }
                
            })
            .catch((error) => {
                setLoading(false)
                setShow(error.message);
                console.error('Error:', error);
        });
    };

    const clear = () => {
        setMessage({
            full_name: "",
            email: "",
            phone: "",
            subject: "",
            body: ""
        })
    }

    return (
        <section className="contact-section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-12 col-sm-12 contact-column">
                        <div className="contact-info">
                            <div className="contact-title">Get In Touch</div>
                            <div className="row">
                                <div className="col-lg-6 col-md-12 col-sm-12 column">
                                    <div className="left-column single-info centred">
                                        <div className="icon-box"><i className="flaticon-map"></i></div>
                                        <h5>Address</h5>
                                        <div className="text">Prince and Princess Estate, 1st Gate, Drive 3, House 23B, Abuja</div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12 col-sm-12 column">
                                    <div className="right-column single-info">
                                        <div className="icon-box"><i className="flaticon-mail"></i></div>
                                        <h5>Email</h5>
                                        <div className="text">info@Jomann.org.ng</div>
                                    </div>
                                    <div className="right-column single-info">
                                        <div className="icon-box"><i className="flaticon-phone-call-1"></i></div>
                                        <h5>Phone No</h5>
                                        <div className="text">+2348060269189</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 contact-column">
                        <div className="contact-form-area">
                            <div className="contact-title">Send Us A Message</div>
                            <form id="contact-form" className="default-form">
                                <div className="row">
                                    <div className="form-group col-lg-12 col-md-12 col-sm-12">
                                        {show ? (
                                            <div class="alert alert-secondary" role="alert">
                                                {show}
                                            </div>
                                        ) : (
                                            <div></div>
                                        )}
                                    </div>
                                    <div class="form-group col-lg-6 col-md-6 col-sm-12">
                                        <label>Name *</label>
                                        <input type="text" name="full_name"
                                        onChange={(e) => {
                                            setMessage({...message, full_name: e.target.value})
                                        }} required="" />
                                    </div>
                                    <div className="form-group col-lg-6 col-md-6 col-sm-12">
                                        <label>Email *</label>
                                        <input type="email" name="form_email" onChange={(e) => {
                                            setMessage({...message, email: e.target.value})
                                        }} required="" />
                                    </div>
                                    <div className="form-group col-lg-6 col-md-6 col-sm-12">
                                        <label>Phone *</label>
                                        <input type="text" name="phone" onChange={(e) => {
                                            setMessage({...message, phone: e.target.value})
                                        }} required="" />
                                    </div>
                                    <div className="form-group col-lg-12 col-md-12 col-sm-12">
                                        <label>Subject</label>
                                        <input type="text" name="form_subject"  onChange={(e) => {
                                            setMessage({...message, subject: e.target.value})
                                        }} required="" />
                                    </div>
                                    <div className="form-group col-lg-12 col-md-12 col-sm-12">
                                        <label>Messsage</label>
                                        <textarea name="form_message" required=""  onChange={(e) => {
                                            setMessage({...message, body: e.target.value})
                                        }}></textarea>
                                    </div>
                                    <div className="form-group col-lg-12 col-md-12 col-sm-12">
                                       {loading ? (
                                        <h2>Sending....</h2>
                                       ) : (
                                        <button className="theme-btn" 
                                        onClick={(e) => {
                                        e.preventDefault();
                                        createMessage()
                                    }}
                                        >Submit</button>
                                       )}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContactSection;