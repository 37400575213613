import React from 'react';
import { Link } from "react-router-dom";
import Bannner from '../../images/help.jpeg';

const CauseDetailHeader = () => {
    return (
        <section className="page-title centred" style={{ backgroundImage: `url(${Bannner})`}}>
            <div className="container">
                <div className="content-box">
                    <div className="title">Project Detail</div>
                    <ul className="bread-crumb">
                        <li><Link to="/">Home</Link></li>
                        <li>Projects</li>
                    </ul>
                </div>
            </div>
        </section>
    )
}

export default CauseDetailHeader;