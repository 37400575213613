import React from 'react';
import BlogDetailHeader from '../components/blogDetail/BlogDetailHeader';
import BlogDetailSection from '../components/blogDetail/BlogDetailSection';

const BlogDetail = () => {
    return (
        <div>
            <BlogDetailHeader />
            <BlogDetailSection />
        </div>
    )
}

export default BlogDetail;